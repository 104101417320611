import { IProjectUserPolicy, IPublicProjectPolicy } from 'atoms/publicProjectPoliciesAtom';
import { NotificationSettings } from 'components/UserSettings/NotificationSettings';
import { UnitSettings } from 'components/UserSettings/PreferenceSettings';
import config from './config';
import { Axios } from 'axios';
import { Buffer } from 'buffer';
const axios: Axios = require('axios').default;

function sanitizeString(str) {
    str = str.replace(/[^a-z0-9áéíóúñü .,_-]/gim, "");
    return str.trim();
}

const API = {

    //#region ==== USER ====
    /**
     * Login: returns a session token.
     * @param email user email
     * @param password user password
     * @returns a session token
     */
    login: async (email: string, password: string) => {
        try {
            return await axios.post(`${config.server}/login`, { email: email, password: password, stay_authenticated: true });
        } catch (error) {
            return Promise.reject(error);
        }
    },

    /**
     * returns the roles of the user as an array
     * @returns The roles of the user as an array
     */
    getUserRoles: async (): Promise<any> => {
        try {
            let result = await axios.get(`${config.server}/api/v2/pub/caller/role`);
            return Promise.resolve(result.data);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * returns the permissions of the user as an array
     * @returns The permissions of the user as an array
     */
    getUserPermissions: async (): Promise<Array<any>> => {
        try {
            return await axios.get(`${config.server}/api/v2/pub/user/role/get/user/permissions`);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    /**
     * createAccount: Sends and invite email to create an account.
     * @param name The name of the user to invite
     * @param email The email of the user to invite
     * @param password The temp password for the invitation
     * @param invite_token (Optional) A token necessary for the creation of the account 
     * @returns Resolve with message if account was created or invite mail was sent. Reject otherwise.
     */
    createAccount: async (name: string, email: string, password: string, invite_token: string | null = null): Promise<any> => {
        try {
            return await axios.post(`${config.server}/user/create`, {
                name: name,
                email: email,
                password: password,
                invite_token: invite_token
            });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Deletes the account fo the user that is currently logged in.
     * @returns 
     */
    deleteAccount: async () => {
        try {
            return await axios.delete(`${config.server}/api/v1/user/delete/account`);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * getSessionTokenFromNewAccountToken: When a user click the link on the verification email, the user is redirected 
     * to the backend server which creates a new account, and then redirects back to the 
     * frontend with the url new/account. This call back contains a createAccount token.
     * Then the front end must call this function to get a session token. This function 
     * expects the createAccount token to be sent as a parameter.
     * @param createAccountToken: create account token.
     * @returns a session token.
     */
    getSessionTokenFromNewAccountToken: async (createAccountToken: string): Promise<any> => {
        try {
            return await axios.post(`${config.server}/api/v1/user/new/login`, {
                access_token: createAccountToken
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    resendInvitation: async (email: string): Promise<any> => {

        try {
            return await axios.post(`${config.server}/api/v2/team/role/send/invite`, {
                email: email
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    //#endregion

    //#region ===== USER INFO  ===== 
    /**
     * Returns the queried user fields.
     * @param queries Which information should be fetched
     * @returns 
     */
    getUserInfo: async (...queries: ('phone' | 'avatar' | 'name' | 'email' | 'registered' | 'activeTeam' | 'lastLogin')[]) => {
        try {
            let params = {
                queries: queries
            };
            let result = await axios.get(`${config.server}/api/v4/share3/user/info`, {
                params: params
            });
            return Promise.resolve(result.data);
        } catch (error: any) {
            return Promise.reject(error.response);
        }
    },
    /**
     * Returns the queried user fields.
     * @param queries Which information should be fetched
     * @returns 
     */
    getUserInfoForId: async (id: string, queries: ('phone' | 'avatar' | 'name' | 'email' | 'registered' | 'activeTeam' | 'lastLogin')[]) => {
        try {
            if (!(id?.length > 0))
                return Promise.reject("No ID provided");

            let params = {
                queries: queries
            };
            let result = await axios.get(`${config.server}/api/v4/share3/user/${id}/info`, {
                params: params
            });
            return Promise.resolve(result.data);
        } catch (error: any) {
            return Promise.reject(error.response);
        }
    },
    /**
     * Sets the username of the current logged in user to the given one.
     * @param username The desired new username
     * @returns A promise that resolves if it was set and rejects on error
     */
    setUsername: async (username: string): Promise<any> => {
        try {
            return await axios.put(`${config.server}/api/v1/user/update/name`, {
                name: username
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * API call to get the avatar for the currently logged in the user
     * @returns The avatar (as DataURL) if present, null if none was set.
     */
    getUserAvatar: async (): Promise<any | null> => {
        try {
            return await axios.get(`${config.server}/api/v1/user/avatar`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Sets the avatar of the currently logged in user to the given one.
     * @param avatarURI The data URI for the desired avatar
     * @returns A promise that resolves if it was set and rejects on error
     */
    setUserAvatar: async (URI: string, color: string): Promise<any | null> => {
        try {
            return await axios.put(`${config.server}/api/v1/user/avatar`, {
                avatar: URI,
                backgroundColor: color
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Sets the avatar of the currently logged in user to the given one.
     * @param teamID The team id for the desired team
     * @returns A promise that resolves if it was set and rejects on error
     */
    setUserActiveTeam: async (teamID: string): Promise<any | null> => {
        try {
            return await axios.post(`${config.server}/api/v2/user/role/setActiveTeam`, {
                teamid: teamID
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Sets the avatar of the currently logged in user to the given one.
     * @param teamID The team id for the desired team
     * @returns A promise that resolves if it was set and rejects on error
     */
    setUserPhone: async (phoneNumber: string): Promise<any | null> => {
        try {
            return await axios.put(`${config.server}/api/v1/user/phone/number`, {
                phoneNumber: phoneNumber
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Fetches the user's phone number
     * @returns The phone number if present, null if none was set.
     */
    getUserPhone: async (): Promise<any | null> => {
        try {
            return await axios.get(`${config.server}/api/v1/user/phone/number`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    //#endregion

    //#region ---TEAMS---
    /**
     * Returns a promise containing all the teams the user belongs to.
     * @returns 
     */
    getUserTeams: async (): Promise<any | null> => {
        try {
            return await axios.get(`${config.server}/api/v3/user/role/teams`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * 
     * @param teamId: team.TeamID not tea,._id a GUID not a mongoId.
     * @returns an array of team members.
     */
    getTeamMembers: async (teamId: string): Promise<any | null> => {
        try {
            const teamMembers = await axios.get(`${config.server}/api/v3/user/role/teams/members/${teamId}`);
            return teamMembers?.data?.teamUsers ?? [];

        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
    * Returns a promise containing the active team for this user.
    * @returns 
     */
    getUserActiveTeam: async (): Promise<any | null> => {
        try {
            return await axios.get(`${config.server}/api/v2/user/role/getActiveTeam`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
   * Returns a promise containing all providers.
   * @returns 
    */
    getProvider: async (): Promise<any | null> => {
        try {
            return await axios.get(`${config.server}/api/v2/provider/role/getAllProviders`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Creates a new team. The current user will be set as creator and as admin.
     * @param name The name for the new team.
     * @returns Resolve if the team was created, reject otherwise
     */
    createTeam: async (name: string): Promise<any | null> => {
        try {
            return await axios.post(`${config.server}/api/v4/share3/team/create`, {
                name: name
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Deletes the given team.
     * The current user needs to be team admin
     * @param teamID THe ID of the team to delete (note: this is the team.teamID, not the team._id)
     * @returns Resolve if the team is deleted, reject otherwise
     */
    deleteTeam: async (teamID: string): Promise<any | null> => {
        try {
            return await axios.delete(`${config.server}/api/v4/team/user/policy/role/deleteTeam/${teamID}`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Makes the current user leave the given team.
     * The current user can not be the sole team admin!
     * @param teamID The ID of the team to leave (note: this is the team.teamID, not the team._id)
     * @returns Resolve if user left the team, reject otherwise
     */
    leaveTeam: async (teamID: string): Promise<any | null> => {
        try {
            return await axios.put(`${config.server}/api/v4/team/member/leaveTeam/${teamID}`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Updates the name of the given team.
     * @param teamID The ID of the team to update
     * @param name The new name of the team
     * @returns {Promise} Resolves is the name is successfully set, reject otherwise
     */
    setTeamName: async (teamID: string, name: string) => {
        try {
            return await axios.put(`${config.server}/api/v2/user/admin/team/project/updateTeamName`, {
                teamID: teamID,
                newName: name
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
    * Returns a promise containing all the avatars for users in the team.
    * @param teamID The team id for which to fetch the avatars
    * @returns 
    */
    getTeamAvatars: async (teamID: string): Promise<any> => {
        try {
            return await axios.get(`${config.server}/api/v4/share3/team/teamAvatars`, {
                data: {
                    teamID: teamID
                }
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Returns a promise containing the avatar of the team.
     * @param teamID The team id for which to fetch the avatars
     * @returns 
     */
    getTeamAvatar: async (teamID: string): Promise<any> => {
        try {
            return await axios.get(`${config.server}/api/v4/share3/team/avatar`, {
                data: {
                    teamID: teamID
                }
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
    * Sets the avatar of the team
    * @param teamID The team id for which to fetch the avatars
    * @returns 
    */
    setTeamAvatar: async (teamID: string, avatarURI: string, bgColor: string): Promise<any> => {
        try {
            return await axios.put(`${config.server}/api/v4/share3/team/avatar`, {
                teamID: teamID,
                backgroundColor: bgColor,
                avatar: avatarURI
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Assigns a team policy role to the given user in the given team.
     * The current user needs to be team admin.
     * @param userId The id of user to assign the policy role to
     * @param teamID The ID of the team in which to assign the policy role (note: tis is the team.teamID, not the team._id)
     * @param teamRoleName The name of the policy role
     * @returns Resolve if the policy role was assigned, reject otherwise
     */
    setUserTeamPolicyRoleId: async (userId: string, teamID: string, teamRoleId: string): Promise<any> => {
        try {
            return await axios.put(`${config.server}/api/v1/team/user/policy/role/ids/${teamRoleId}/${userId}/${teamID}`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Removes the given user from the given team.
     * @param userId The id of the user to remove (note: this is the user._id, the id of the user doc)
     * @param teamID The id of the team from which to remove the user (note: this is the <b>teamID</b>, not the _id of the team doc)
     * @returns Resolve if user was successfully removed, reject otherwise
     */
    removeUserFromTeam: async (userId: string, teamID: string): Promise<any> => {
        try {
            //requires team admin team policy role.
            const result = await axios.put(`${config.server}/api/v1/team/user/policy/role/delete/team/member/${userId}/${teamID}`);
            return result?.data;
        } catch (error: any) {
            if (error?.request?.response === 'failed at least one team admin validation.') {
                return Promise.reject('failed at least one team admin validation.');
            }
            return Promise.reject(error);
        }
    },
    /**
     * Removes the given user from the given team.
     * @param email TThe email of the user to add.
     * @param teamID The id of the team to add the user to (note: this is the <b>teamID</b>, not the _id of the team doc)
     * @returns Resolve if user was successfully added or invited, reject otherwise
     */
    addUserToTeam: async (email: string, teamID: string, roleId: string): Promise<any> => {
        try {
            //requires team admin team policy role.
            const result = await axios.post(`${config.server}/api/v3/user/admin/team/plan/validate/user/inviteToTeam`, {
                email: email,
                teamID: teamID,
                roleId: roleId
            });
            return result?.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Fetches the role of the given user in the given team.
     * The current user needs to be a team admin
     * @param userId The id of the user to get the team role for
     * @param teamID The ID of the team in which to check the user's role (note: this is the team.teamID, not the team._id)
     * @returns Resolve containing the role if found, reject otherwise
     */
    getUserTeamPolicyRoleName: async (userId: string, teamID: string): Promise<any> => {
        try {
            return await axios.get(`${config.server}/api/v1/team/user/policy/role/${userId}/${teamID}`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Checks if the current user is an admin for the given team.
     * The current user needs to belong to the given team.
     * @param teamID The ID of the team for which to check if the current user is an admin  (note: this is the team.teamID, not the team._id)
     * @returns Resolve containing true or false, reject if an error occurred.
     */
    isTeamAdmin: async (teamID: string): Promise<any> => {
        try {
            const result = await axios.get(`${config.server}/api/v3/user/role/has/team/admin/policy/${teamID}`)
            return result?.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    //#endregion

    //#region ==== USER SETTINGS ==== //
    /**
     * @param email The users' email.
     * @param password The new password given by the user.
     * @param temppassword Temporary password which is sent by the email. Functions kind of like a token.
     * @returns 
     */
    resetUserPassword: async (email: any, password: string, temppassword: any): Promise<any> => {
        try {
            return await axios.post(`${config.server}/user/resetPassword`, {
                email: email,
                password: password,
                temporalPassword: temppassword
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * @param email The users' email which requested a new password.
     * @returns 
     */
    requestForgetPassword: async (email: string): Promise<any> => {
        try {
            return await axios.post(`${config.server}/user/forgotPassword`, {
                email: email
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * API call that gets the current notif settings for the logged in user.
     * @returns {Promise} A promise that resolves with the user settings if they were found, resolves null if not found and rejects on error
     */
    getUserSettings: async (): Promise<any | null> => {
        try {
            return await axios.get(`${config.server}/api/v2/user/role/settings`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Creates or updates the uer settings fo the current user to those given.
     * @param settings The new or updated user settings
     * @returns Resolve with update settings if they were created/updated, reject otherwise
     */
    setUserSettings: async (settings: any): Promise<any | null> => {
        try {
            return await axios.put(`${config.server}/api/v2/user/role/settings`,
                settings
            )
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Sets the preferred language for the current user.
     * @param language The language the user prefers. Note that the language should follow the language tags format: lng-(script)-(REGION)-(extensions) (https://www.w3.org/International/articles/language-tags/).
     * @returns Resolve if the language was set, reject otherwise.
     */
    setLanguage: async (language: string): Promise<any | null> => {
        try {
            return await axios.put(`${config.server}/api/v2/user/role/settings`, {
                language: language
            })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * API call that gets the current notif settings for the logged in user.
     * @returns {Promise}  A promise that resolves with the notif settings if they were found, resolves null if not found and rejects on error
     */
    getNotifSettings: async (): Promise<any> => {
        try {
            let req = await axios.get(`${config.server}/api/v4/share3/user/notificationsettings`)
            if (req)
                return req.data;
            return Promise.reject("Not found");

        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Sets the notif settings to those that are given.
     * @param settings The notification settings that should be set.
     * @returns {Promise} A promise that resolves with the notif settings if they were set, null if not found and rejects on error
     */
    setNotifSettings: async (settings: any): Promise<NotificationSettings[] | null> => {
        try {
            return await axios.put(`${config.server}/api/v4/share3/user/notificationsettings`, { settings: settings })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * API call that gets the current notif settings for the logged in user.
     * @returns {Promise}  A promise that resolves with the notif settings if they were found, resolves null if not found and rejects on error
     */
    getUnitSettings: async (): Promise<any> => {
        try {
            return await axios.get(`${config.server}/api/v4/share3/user/unitsettings`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Sets the notif settings to those that are given.
     * @param settings The notification settings that should be set.
     * @returns {Promise} A promise that resolves with the notif settings if they were set, null if not found and rejects on error
     */
    setUnitSettings: async (settings: UnitSettings): Promise<any> => {
        try {
            return await axios.put(`${config.server}/api/v4/share3/user/unitsettings`,
                {
                    settings: settings
                })
        } catch (error) {
            return Promise.reject(error);
        }
    },
    //#endregion

    //#region ===== PROJECTS ===== //
    /**
     * Fetches the modelnodes for the given project ID
     * @param projectID The id for the desired project (note: this is the node.projectID, not the node._id)
     * @returns Resolve containing the modelnodes for the project if found, reject otherwise
     */
    getProjectModelNodes: async (projectID: string): Promise<any> => {
        try {
            return await axios.get(`${config.server}/api/v4/pub/project/getMMProjects?projectId=${projectID}`)
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Checks whether the current user is authorized to access the given project.
     * @param {string} projectID The ID to check the access for
     * @returns Resolve containing access permissions, reject on failed fetch
     */
    getProjectAccess: async (projectID: string): Promise<any> => {
        try {
            return await axios.get(`${config.server}/api/v2/pub/access/${projectID}`);

        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Gets the last opened projects by the current user (max 20).
     * @returns Resolve containing project info of the last opened projects.
     */
    getLastOpenedProjects: async (): Promise<any> => {
        try {
            const requestResult = await axios.get(`${config.server}/api/v1/latest/opened/user/projects`);
            return requestResult?.data ?? [];
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Notifies the backend that the current user has opened a project.
     * @param projectId The ID of the project that was opened, (note: this is the project.projectID, not the node._id)
     * @returns Resolve if the activity was registered, reject otherwise
     */
    registerActivityProjectOpened: async (projectId: string): Promise<any> => {
        try {
            return await axios.get(`${config.server}/api/v1/register/opened/project/${projectId}`);
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Fetches all available project for the current user (based on the given params).
     * @param includeArchived Include archived projects
     * @param getPublicProjects Include projects that are public
     * @param getThumbnailDataUrl Include the thumbnails for each returned project
     * @returns Resolve containing all projects available to the user (based on given params), reject on error or if none found
     */
    getProjects: async (includeArchived: boolean, getPublicProjects: boolean = false, getThumbnailDataUrl: boolean = false): Promise<any> => {
        const getReferenceProjects = true;
        try {
            return await
                axios.get(
                    `${config.server}/api/v4/pub/project/getMMProjects?itemtype=projectinfo&archived=${includeArchived}&getPublic=${getPublicProjects}&getReferenceProjects=${getReferenceProjects}&getThumbnailDataUrl=${getThumbnailDataUrl}`
                )
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    getProjectInfo: async (projectId: string): Promise<any> => {
        try {
            return await axios.get(`${config.server}/api/v4/share3/project/info?projectId=${projectId}`);
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    // 
    getProjectUsers: async (projectId: string): Promise<any> => {
        try {
            return await axios.get(`${config.server}/api/v2/user/role/project/users/${projectId}`);
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    /**
    * Creates a new project in the given team with the given name and state.
    * @param projectName Name of the new project
    * @param teamId Team of the new project
    * @returns Resolve containing the new project, reject on error
    */
    createProject: async (teamId: string, projectName: string): Promise<any> => {
        try {
            return await
                axios.post(
                    `${config.server}/api/v5/user/role/create/project/${teamId}`,
                    {
                        name: projectName
                    }
                )
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Fetches the thumbnail for the given project
     * @param projectId The ID of the project for which to fetch the thumbnail (note: this is the project.projectID, not the node._id)
     * @returns Resolve containing the thumbnail, reject on error 
     */
    getProjectThumbnail: async (projectId: string): Promise<any> => {
        if (!(projectId?.length > 0))
            return Promise.reject("restAPI::getProjectThumbnail(): Please provide a proper project ID!");
        try {
            var result = await axios.get(`${config.server}/api/v2/efs/datastore/getFile?path=/projectData/${projectId}/thumbnail.png`, { responseType: 'arraybuffer' });
            if (!result)
                return Promise.reject("Could not find thumbnail");
            var base64 = Buffer.from(result.data, 'base64').toString('base64');
            return Promise.resolve("data:image/png;base64," + base64);
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Fetches the thumbnail for the given project
     * @param projectId The ID of the project for which to fetch the thumbnail (note: this is the project.projectID, not the node._id)
     * @returns Resolve containing the thumbnail, reject on error 
     */
    getModelThumbnail: async (projectId: string, modelId: string): Promise<any> => {
        if (!(projectId?.length > 0)) {
            return Promise.reject("restAPI::getModelThumbnail(): Please provide a proper project ID!");
        }
        if (!(modelId?.length > 0)) {
            return Promise.reject("restAPI::getModelThumbnail(): Please provide a proper mode ID!");
        }
        try {
            var result = await axios.get(`${config.server}/api/v2/efs/datastore/getFile?path=/projectData/${projectId}/${modelId}/thumbnail.png`, { responseType: 'arraybuffer' });
            var base64 = Buffer.from(result.data, 'base64').toString('base64');
            return Promise.resolve("data:image/png;base64," + base64);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * Fetches the thumbnail for the given project
     * @param projectId The ID of the project for which to fetch the thumbnail (note: this is the project.projectID, not the node._id)
     * @returns Resolve containing the thumbnail, reject on error 
     */
    getNodeThumbnail: async (projectId: string,nodeId: string): Promise<any> => {
        if (!(nodeId?.length > 0))
            return Promise.reject("restAPI::getNodeThumbnail(): Please provide a proper node ID!");
        try {
            var result = await axios.get(`${config.server}/api/v2/efs/datastore/getFile?path=/projectData/${projectId}/${nodeId}/thumbnail.png`, { responseType: 'arraybuffer' });
            if (!result)
                return Promise.reject("Could not find thumbnail");
            var base64 = Buffer.from(result.data, 'base64').toString('base64');
            return Promise.resolve("data:image/png;base64," + base64);
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    //#endregion

    //#region ==== SITE FUNCTIONALITY
    /**
     * Performs a search for projects,issues and documents based on the search term.
     * @param searchTerm 
     * @param limit Amount that 
     * @param skip 
     * @returns 
     */
    performSearchQuery: async (searchTerm: string, limit: number, skip: number) => {
        try {
            let results = await
                axios.get(
                    `${config.server}/api/v1/search/project/user/data/${sanitizeString(searchTerm)}/${limit.toString()}/${skip.toString()}`
                    , {
                        validateStatus: function (status) {
                            return status >= 200 && status < 300; // Resolve only if the status code is a 2xx status
                        }
                    });
            if (!results?.data)
                return [];
            return results.data
        }
        catch (error) {
            return Promise.reject(error);
        }
    },
    //#endregion

    //#region ------- Teams ---------
    /**
     * Gets the current user's role for the given team
     * The current user needs to belong to the given team.
     * @param teamID The ID of the team for which to get the current user's role (note: this is the team.teamID, not the team._id)
     * @returns Resolve containing the current user's role for the given team
     */
    getTeamPolicyRoles: async (teamID: string): Promise<any> => {
        try {
            const result = await axios.get(`${config.server}/api/v1/team/member/policy/roles/${teamID}`)
            return result?.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    /** 
     * upsertTeamPolicyRole
     * @param teamID: team.teamID not team._id
     * @param name: : the team policy name
     * @param policyStatements: the team policy statements
     * @returns: the result of teh operation
     */
    insertTeamPolicyRole: async (teamID: string, name: string, policyStatements: Array<{ resource: string, name: string }>): Promise<any> => {
        try {
            const result = await axios.post(`${config.server}/api/v1/team/user/policy/role/team/policy/${teamID}`, {
                teamID: teamID,
                name: name,
                policyStatements: policyStatements
            })
            return result?.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * deleteTeamPolicy
     * @param teamID: team.teamID not team._id
     * @param policyId: the team policy Id
     * @returns: the result of teh operation
     */
    deleteTeamPolicy: async (teamID: string, policyId: string): Promise<any> => {
        try {
            const result = await axios.delete(`${config.server}/api/v1/team/user/policy/role/team/policy/${policyId}/${teamID}`)
            return result?.data;
        } catch (error: any) {
            if (error?.request?.response === 'failed at least one team admin validation.') {
                return Promise.reject('failed at least one team admin validation.');
            } else if (error?.request?.response === 'Not allowed. Team has members assigned to the role to be deleted.') {
                return Promise.reject('Not allowed. Team has members assigned to the role to be deleted.');
            }
            return Promise.reject(error);
        }
    },
    /**
     * updateTeamPolicyName
     * @param teamID: team.teamID not team._id
     * @param policyId: the team policy Id
     * @param name: the team policy name
     * @returns: the result of teh operation
     */
    updateTeamPolicyName: async (teamID: string, policyId: string, name: string): Promise<any> => {
        try {
            const result = await axios.put(`${config.server}/api/v1/team/user/policy/role/team/policy/${policyId}/${name}/${teamID}`)
            return result?.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * updateStatementNameByResource
     * @param teamID: team.teamID not team._id
     * @param policyId : the team policy Id
     * @param resource: the team policy resource
     * @param name: the team policy name
     * @returns: the result of teh operation
     */
    updateStatementNameByResource: async (teamID: string, policyId: string, resource: string, name: string): Promise<any> => {
        try {
            const result = await axios.put(`${config.server}/api/v1/team/user/policy/role/team/statement/name/${policyId}/${resource}/${name}/${teamID}`)
            return result?.data;
        } catch (error: any) {
            if (error?.request?.response === 'failed at least one team admin validation.') {
                return Promise.reject('failed at least one team admin validation.');
            }
            return Promise.reject(error);
        }
    },
    deleteNode: async (nodeId: string): Promise<any> => {
        try {
            const result = await axios.delete(`${config.server}/api/v3/node/data?_id=${nodeId}`);
            return Promise.resolve(result?.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    updateNodes: async (nodeData: any[]): Promise<any> => {
        try {
            const result = await axios.post(`${config.server}/api/v3/node/data/array/upsert`, {
                nodeDataArray: nodeData
            });
            Promise.resolve(result?.data);
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    getIssuesCreatedByMe: async (priorities: string[] = undefined, states: string[] = undefined): Promise<any | null> => {
        try {
            let filter = undefined;
            if (priorities) {
                filter = { priorities: priorities }
            }
            if (states) {
                if (!filter) { filter = {}; }
                filter.states = states;
            }
            const result = await axios.post(`${config.server}/api/v2/user/role/get/created/by/me/issues`, filter);
            return result?.data;

        } catch (error) {
            return Promise.reject(error);
        }
    },
    getIssuesAssignedToMe: async (priorities: string[] = undefined, states: string[] = undefined): Promise<any | null> => {
        try {
            let filter = undefined;
            if (priorities) {
                filter = { priorities: priorities }
            }
            if (states) {
                if (!filter) { filter = {}; }
                filter.states = states;
            }
            const result = await axios.post(`${config.server}/api/v2/user/role/get/assigned/to/me/issues`, filter);
            return result?.data;

        } catch (error) {
            return Promise.reject(error);
        }
    },
    getUserProjects: async (limit = 20, skip = 0, search?: string): Promise<any> => {
        try {
            const requestResult = await axios.get(`${config.server}/api/v1/project/user/data`, { params: { skip: skip, limit: limit, search: search } });
            return requestResult?.data ?? [];
        } catch (error) {
            return Promise.reject(error);
        }
    },
    searchProjectUsers: async (projectId, searchTerm, maxLengthResult = 10): Promise<any> => {
        try {
            const requestResult = await axios.get(`${config.server}/api/v1/search/project/users/${projectId}/${searchTerm}/${maxLengthResult}`);
            return requestResult?.data?.users ?? [];
        } catch (error) {
            return Promise.reject(error);
        }
    },
    updateIssueAssignedTo: async (issueId: string, assignedTo: string): Promise<any> => {
        try {
            const result = await axios.put(`${config.server}/api/v1/meta/issue/assigned/to/${assignedTo}/${issueId}`)
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    updateIssueState: async (issueId: string, issueState: string): Promise<any> => {
        try {
            const result = await axios.put(`${config.server}/api/v1/meta/issue/state/${issueState}/${issueId}`)
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    addIssueComment: async (issueId: string, comment: string): Promise<any> => {
        try {
            const result = await axios.put(`${config.server}/api/v1/meta/issue/add/comment/${issueId}`,
                {
                    comment: comment,
                });
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    updateIssueProperty: async (issueId: string, issuePropertyName: string, issuePropertyValue: string, issuePropertyKey?: string, issuePropertyType?: string): Promise<any> => {
        try {
            const result = await axios.put(`${config.server}/api/v1/meta/issue/property/${issueId}`,
                {
                    propName: issuePropertyName,
                    propValue: issuePropertyValue,
                    propKey: issuePropertyKey,
                    propType: issuePropertyType
                });
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    upsertUserProjectSettings: async (projectId: string, name: string, objectType: string, propertyName: string, propertyValue: string, projectSearchId: string): Promise<any> => {
        try {
            const result = await axios.put(`${config.server}/api/v1/share/project/user/search/settings?projectId=${projectId}`, {
                _id: projectSearchId,
                name: name,
                objectType: objectType,
                propertyName: propertyName,
                propertyValue: propertyValue
            });
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    getUserProjectSettings: async (projectId: string): Promise<any> => {
        try {
            if (!projectId) {
                return [];
            }
            const result = await axios.get(`${config.server}/api/v1/share/project/user/search/settings?projectId=${projectId}`);
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    deleteUserProjectSettings: async (projectId: string, projectSearchId: string): Promise<any> => {
        try {
            const result = await axios.delete(`${config.server}/api/v1/share/project/user/search/settings?projectId=${projectId}&projectSearchId=${projectSearchId}`);
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    /**
     * deleteUserFromProject: deletes a user from a project.
     * @param email: the user to be deleted.
     * @param projectId: the projectId of the project.
     * @returns A confirmation message that the user has been removed from the project.
     */
    deleteUserFromProject: async (email: string, projectId: string) => {
        try {
            const result = await axios.delete(`${config.server}/api/v1/user/admin/team/deleteUserFromProject`, {
                data: {
                    projectId: projectId,
                    email: email,
                }
            });
            return result?.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getIsProjectPublic: async (projectId): Promise<boolean> => {
        try {
            const requestResult = await axios.get(`${config.server}/api/v2/pub/project/is/public/${projectId}`);
            return requestResult?.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    /**
     * getProjectUsersWithPolicyRoles: get the project users with their corresponding policy roles if any.
     * @param projectId: the projectId of the project
     * @returns an array of users objects, and a policy property if any.
     */
    getProjectUsersWithPolicyRoles: async (projectId): Promise<[]> => {
        try {
            const requestResult = await axios.get(`${config.server}/api/v4/share/project/users/policies`,
                { params: { projectId: projectId } });
            return Promise.resolve(requestResult?.data ?? []);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPublicProjectPolicyStatements: async (projectId: string): Promise<IPublicProjectPolicy | null> => {
        try {
            const requestResult = await axios.get(`${config.server}/api/v2/pub/project/policy/role/${projectId}`);
            return { policies: requestResult?.data?.policies, isPublic: requestResult?.data.isPublic, projectId: projectId };
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    /**
     * Gets the projects user policy statements with their ids. 
     * @param projectId 
     * @returns and array with project user polices.
     */
    getProjectsUserPolicies: async (projectId): Promise<IProjectUserPolicy[] | null> => {
        try {
            const requestResult = await axios.get(`${config.server}/api/v2/share/project/policy/roles/${projectId}`);
            const policies = requestResult?.data?.map(policy => {
                return {
                    id: policy._id,
                    name: policy.Name,
                    policies: policy.Statements?.map(statement => {
                        return {
                            name: statement.Name,
                            resource: statement.Resource
                        }
                    })
                }
            }).reverse() ?? [];
            return policies;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    /**
     * addProjectUserWithPolicyRole: adds a user and a users associated policy role to a project.
     * @param email: user to add to the project.
     * @param projectId: projectId of the project.
     * @param policyId: policyId of the policy associated with the user.
     * @returns a confirmation message if the user was added or not to the project.
     */
    addProjectUserWithPolicyRole: async (email: string, projectId: string, policyId: string): Promise<any> => {
        try {
            const result = await axios.put(`${config.server}/api/v3/team/user/policy/role/add/project/member`, {
                projectId: projectId,
                email: email,
                policyId: policyId
            });
            return result?.data;
        } catch (error: any) {
            if (error?.response?.data?.message?.indexOf('already belongs to the project') > 0 ||
                error?.response?.data?.message?.indexOf('is already a member of the projects team') > 0) {
                return Promise.reject(error.response.data.message);
            }
            return Promise.reject(error);
        }
    },
    /**
     * updateProjectUserPolicyRole: updates a projects users policy role.
     * @param email: the users being updated.
     * @param projectId: the projectId of the project.
     * @param policyId: the policyId to be set.
     * @returns an instance of the policy created.
     */
    updateProjectUserPolicyRole: async (email: string, projectId: string, policyId: string) => {
        try {
            const result = await axios.put(`${config.server}/api/v1/team/user/policy/role/assign/project/member/policy`, {
                projectId: projectId,
                email: email,
                policyId: policyId
            });
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    updatePublicProjectPolicies: async (projectId: string, policies: Array<{ name: "read" | "none", resource: string }>) => {
        try {
            const result = await axios.post(`${config.server}/api/v1/team/user/policy/role/assign/project`, {
                projectId: projectId,
                policyStatements: policies
            });
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    getIssueTimeline: async (issueId): Promise<Array<any>> => {
        try {
            const requestResult = await axios.get(`${config.server}/api/v3/node/data/issue/timeline/${issueId}?interval=5`);
            return requestResult?.data ?? [];
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    togglePublicProjectSetting: async (projectId: string): Promise<any> => {
        try {
            const requestResult = await axios.post(`${config.server}/api/v2/user/admin/team/project/toogleIsPublic`, {
                projectID: projectId
            });
            return requestResult;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    setIsPublicProjectSetting: async (projectId: string, isPublic: boolean): Promise<any> => {
        try {
            const requestResult = await axios.post(`${config.server}/api/v2/user/admin/team/project/set/is/public`, {
                projectId: projectId,
                isPublic: isPublic
            });
            return requestResult;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    editProjectName: async (projectId: string, name: string): Promise<any> => {
        try {
            const requestResult = await axios.put(`${config.server}/api/v3/user/admin/team/project/edit/name`, {
                projectID: projectId,
                name: name
            });
            return requestResult;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    getDocuments: async (projectId: string) => {
        try {
            const result = await axios.get(`${config.server}/api/v1/share3/project/documents/${projectId}`);
            return result?.data;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    addFolder: async (projectId: string, folderName: string, modelReferences?: string) => {
        try {
            const result = await axios.post(`${config.server}/api/v1/share3/project/document`, {
                projectId: projectId,
                folderName: folderName,
                modelReferences: modelReferences
            });
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    deleteFolder: async (nodeId: string) => {
        try {
            const result = await axios.delete(`${config.server}/api/v3/node/data?_id=${nodeId}`);
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    duplicateFolder: async (nodeId: string) => {
        try {
            const result = await axios.post(`${config.server}/api/v1/share3/duplicate/project/document`, {
                nodeId: nodeId,
            });
            return result?.data?.nodeData;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    renameFolder: async (folderId: string, name: string) => {
        try {
            const result = await axios.put(`${config.server}/api/v1/share3/project/document/rename`, {
                nodeId: folderId,
                name: name
            });
            return result?.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    copyFile: async (filePath: string, sourceNodeId: string, targetNodeId: string) => {
        try {
            const fileData: any = {
                filePath,
                nodeId: sourceNodeId
            }
            if (targetNodeId !== undefined && targetNodeId !== sourceNodeId) {
                fileData.targetNodeId = targetNodeId;
            }
            const result = await axios.put(`${config.server}/api/v1/share3/project/document/copy/file`, fileData);
            return result?.data?.nodeData;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    deleteFile: async (nodeId: string, fileName: string) => {
        try {
            const result = await axios.put(`${config.server}/api/v1/meta/issue/remove/document/${nodeId}`, { fileName });
            return result?.data?.meta;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    cutFile: async (filePath: string, sourceNodeId: string, targetNodeId: string) => {
        try {
            const fileData: any = {
                filePath,
                nodeId: sourceNodeId,
                targetNodeId
            }
            if (targetNodeId !== undefined && targetNodeId !== sourceNodeId) {
                fileData.targetNodeId = targetNodeId;
            }
            const result = await axios.put(`${config.server}/api/v1/share3/project/document/cut/file`, fileData);
            return result?.data?.nodeData;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    uploadDocumentsToNode: async (nodeId: string, files: FileList, onProgressEvent?: (event: any) => void) => {
        if (!nodeId || !files || files.length === 0) {
            return Promise.reject("restAPI::uploadDocumentsToNode(): Please provide a nodeId and documents.");
        }

        const options = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
                if (onProgressEvent) {
                    onProgressEvent(progressEvent);
                }
            }
        }

        let formData = new FormData();
        Array.from(files).forEach(file => {
            formData.append('files', file, file.name)
        });

        try {
            const result = await axios.put(`${config.server}/api/v1/meta/node/add/document/${nodeId}`, formData, options);
            return result?.data?.meta;
        } catch (error: any) {
            return Promise.reject(error.response.data);
        }
    },
    createIssueNode: async (projectId: string, issueData: any) => {
        if (!projectId) {
            return Promise.reject("No projectId was given.");
        }

        const options = {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }

        let formData = new FormData();
        Object.keys(issueData).forEach(key => {
            formData.append(key, issueData[key]);
        });

        try {
            const result = await axios.post(`${config.server}/api/v1/share3/project/issue/entities/${projectId}`, formData, options);
            return result?.data.meta[0];
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    deleteFileFromNode: async (nodeId: string, fileName: string): Promise<any> => {
        if (!nodeId || !fileName) {
            return Promise.reject("restAPI::deleteFileFromNode(): Please provide a proper nodeId and file name!");
        }

        try {
            const requestResult = await axios.put(`${config.server}/api/v1/meta/issue/remove/document/${nodeId}`, {
                fileName: fileName
            });
            return requestResult.data.meta;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    downloadFile: async (filePath: string) => {
        try {
            const requestResult = await axios.get(`${config.server}/api/v2/efs/datastore/getFile?path=${filePath}`);
            return requestResult;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    getFileDownloadURL: (filePath: string, sessionToken: string) => {
        return `${config.server}/api/v2/efs/datastore/getFile?path=${filePath}&access_token=${sessionToken}`;
    },
    /**
     * Call to send an email containing the feedback data
     * @param data The feedback data to be sent
     * @returns Email server response
     */
    sendFeedback: async (data: { mood: 'sad' | 'meh' | 'happy', user: string, location: string, userAgent: string, feedback?: string }) => {
        let { mood, user, location, userAgent, feedback } = data;
        try {
            const requestResult = await axios.post(`${config.server}/email/feedback`, {
                mood,
                user,
                location,
                userAgent,
                feedback
            });
            return requestResult;
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
    ImportSets: {
        createImportSet: async (
            title: string, hsbType:string, description: string, modelReferences: Record<string, {handle?:string, ifcId?: string}[]> 
        )=> {
            
            try {
                const result = await axios.post(`${config.server}/api/v1/share/project/import/selection/set`,
                 {
                    importSetName: title,
                    hsbType,
                    comment: description,
                    modelReferences: modelReferences
                 });
                return result?.data;
            } catch (error) {
                return Promise.reject(error);
            }
        },
        updateImportSet: async (nodeId:string, 
            title: string, hsbType:string, description: string
        )=> {
            try {
                const result = await axios.put(`${config.server}/api/v1/share/project/import/selection/set`,
                 {
                    nodeId: nodeId,
                    importSetName: title,
                    hsbType,
                    comment: description
                 });
                return result?.data;
            } catch (error) {
                return Promise.reject(error);
            }
        },
        getProjectsImportSets: async (projectId: string)=> {
            try {
                const result = await axios.get(`${config.server}/api/v1/share/project/import/selection/set/${projectId}`);
                return result?.data;
            } catch (error) {
                return Promise.reject(error);
            }
        },
        deleteImportSet: async (nodeId: string) => {
            try {
                //for now disabled
                const result = await axios.delete(`${config.server}/api/v1/share/project/import/selection/set`, {data: {nodeId}});
                return result?.data;
            } catch (error) {
                return Promise.reject(error);
            }
        },
    },
    deleteProject: async (projectId: string) => {
        try {
            const requestResult = await axios.delete(`${config.server}/api/v4/team/user/policy/role/deleteProject/${projectId}`);
            return Promise.resolve(requestResult);
        } catch (error: any) {
            return Promise.reject(error);
        }
    },
};
export default API;
